import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [import('./viewer/ImageZoom' /* webpackChunkName: "ImageZoom" */)],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('ImageZoom', UiTypes),
};

export default entry;
