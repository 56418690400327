import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import('./viewer/VideoPlayer' /* webpackChunkName: "VideoPlayer" */),
    import(
      './viewer/VideoPlayer.controller' /* webpackChunkName: "VideoPlayer" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('VideoPlayer', UiTypes),
};

export default entry;
