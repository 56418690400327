import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import('./viewer/SearchBox' /* webpackChunkName: "SearchBox" */),
    import('./viewer/SearchBox.controller' /* webpackChunkName: "SearchBox" */),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('SearchBox', UiTypes),
};

export default entry;
