import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  SlideShowPolaroid: () => [
    import(
      './viewer/skinComps/SlideShowPolaroid/SlideShowPolaroid.skin' /* webpackChunkName: "SlideShowGallery_SlideShowPolaroid" */
    ),
    import(
      './viewer/SlideShowGallery.controller' /* webpackChunkName: "SlideShowGallery_SlideShowPolaroid" */
    ),
  ],
  SlideShowTextOverlay: () => [
    import(
      './viewer/skinComps/SlideShowTextOverlay/SlideShowTextOverlay.skin' /* webpackChunkName: "SlideShowGallery_SlideShowTextOverlay" */
    ),
    import(
      './viewer/SlideShowGallery.controller' /* webpackChunkName: "SlideShowGallery_SlideShowTextOverlay" */
    ),
  ],
  SlideShowTextRight: () => [
    import(
      './viewer/skinComps/SlideShowTextRight/SlideShowTextRight.skin' /* webpackChunkName: "SlideShowGallery_SlideShowTextRight" */
    ),
    import(
      './viewer/SlideShowGallery.controller' /* webpackChunkName: "SlideShowGallery_SlideShowTextRight" */
    ),
  ],
  SlideShowScotchTape: () => [
    import(
      './viewer/skinComps/SlideShowScotchTape/SlideShowScotchTape.skin' /* webpackChunkName: "SlideShowGallery_SlideShowScotchTape" */
    ),
    import(
      './viewer/SlideShowGallery.controller' /* webpackChunkName: "SlideShowGallery_SlideShowScotchTape" */
    ),
  ],
  SlideShowTextBottom: () => [
    import(
      './viewer/skinComps/SlideShowTextBottom/SlideShowTextBottom.skin' /* webpackChunkName: "SlideShowGallery_SlideShowTextBottom" */
    ),
    import(
      './viewer/SlideShowGallery.controller' /* webpackChunkName: "SlideShowGallery_SlideShowTextBottom" */
    ),
  ],
  SlideShowTextFloating: () => [
    import(
      './viewer/skinComps/SlideShowTextFloating/SlideShowTextFloating.skin' /* webpackChunkName: "SlideShowGallery_SlideShowTextFloating" */
    ),
    import(
      './viewer/SlideShowGallery.controller' /* webpackChunkName: "SlideShowGallery_SlideShowTextFloating" */
    ),
  ],
  SlideShowGalleryLiftedShadowSkin: () => [
    import(
      './viewer/skinComps/SlideShowGalleryLiftedShadowSkin/SlideShowGalleryLiftedShadowSkin.skin' /* webpackChunkName: "SlideShowGallery_SlideShowGalleryLiftedShadowSkin" */
    ),
    import(
      './viewer/SlideShowGallery.controller' /* webpackChunkName: "SlideShowGallery_SlideShowGalleryLiftedShadowSkin" */
    ),
  ],
  SlideShowGallerySloopy: () => [
    import(
      './viewer/skinComps/SlideShowGallerySloopy/SlideShowGallerySloopy.skin' /* webpackChunkName: "SlideShowGallery_SlideShowGallerySloopy" */
    ),
    import(
      './viewer/SlideShowGallery.controller' /* webpackChunkName: "SlideShowGallery_SlideShowGallerySloopy" */
    ),
  ],
  BlogSlideShow: () => [
    import(
      './viewer/skinComps/BlogSlideShow/BlogSlideShow.skin' /* webpackChunkName: "SlideShowGallery_BlogSlideShow" */
    ),
    import(
      './viewer/SlideShowGallery.controller' /* webpackChunkName: "SlideShowGallery_BlogSlideShow" */
    ),
  ],
  SlideShowIron: () => [
    import(
      './viewer/skinComps/SlideShowIron/SlideShowIron.skin' /* webpackChunkName: "SlideShowGallery_SlideShowIron" */
    ),
    import(
      './viewer/SlideShowGallery.controller' /* webpackChunkName: "SlideShowGallery_SlideShowIron" */
    ),
  ],
  SlideShowCleanAndSimple: () => [
    import(
      './viewer/skinComps/SlideShowCleanAndSimple/SlideShowCleanAndSimple.skin' /* webpackChunkName: "SlideShowGallery_SlideShowCleanAndSimple" */
    ),
    import(
      './viewer/SlideShowGallery.controller' /* webpackChunkName: "SlideShowGallery_SlideShowCleanAndSimple" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('SlideShowGallery', UiTypes),
};

export default entry;
