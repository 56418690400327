import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [import('./viewer/Anchor' /* webpackChunkName: "Anchor" */)],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('Anchor', UiTypes),
};

export default entry;
