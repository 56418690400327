import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import('./viewer/Honeycomb' /* webpackChunkName: "Honeycomb" */),
    import('./viewer/Honeycomb.controller' /* webpackChunkName: "Honeycomb" */),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('Honeycomb', UiTypes),
};

export default entry;
