import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import(
      './viewer/VerticalAnchorsMenu' /* webpackChunkName: "VerticalAnchorsMenu" */
    ),
  ],
  VerticalAnchorsMenuTextSkin: () => [
    import(
      './viewer/skinComps/VerticalAnchorsMenuTextSkin/VerticalAnchorsMenuTextSkin.skin' /* webpackChunkName: "VerticalAnchorsMenu_VerticalAnchorsMenuTextSkin" */
    ),
  ],
  VerticalAnchorsMenuSymbolSkin: () => [
    import(
      './viewer/skinComps/VerticalAnchorsMenuSymbolSkin/VerticalAnchorsMenuSymbolSkin.skin' /* webpackChunkName: "VerticalAnchorsMenu_VerticalAnchorsMenuSymbolSkin" */
    ),
  ],
  VerticalAnchorsMenuSymbolWithTextSkin: () => [
    import(
      './viewer/skinComps/VerticalAnchorsMenuSymbolWithTextSkin/VerticalAnchorsMenuSymbolWithTextSkin.skin' /* webpackChunkName: "VerticalAnchorsMenu_VerticalAnchorsMenuSymbolWithTextSkin" */
    ),
  ],
  VerticalAnchorsMenuSymbolWithHiddenTextSkin: () => [
    import(
      './viewer/skinComps/VerticalAnchorsMenuSymbolWithHiddenTextSkin/VerticalAnchorsMenuSymbolWithHiddenTextSkin.skin' /* webpackChunkName: "VerticalAnchorsMenu_VerticalAnchorsMenuSymbolWithHiddenTextSkin" */
    ),
  ],
  VerticalAnchorsMenuLinkedNoTextSkin: () => [
    import(
      './viewer/skinComps/VerticalAnchorsMenuLinkedNoTextSkin/VerticalAnchorsMenuLinkedNoTextSkin.skin' /* webpackChunkName: "VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkin" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('VerticalAnchorsMenu', UiTypes),
};

export default entry;
