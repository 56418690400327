import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [import('./viewer/WRichText' /* webpackChunkName: "WRichText" */)],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('WRichText', UiTypes),
};

export default entry;
