import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import(
      './viewer/CoBrandingBannerMobile' /* webpackChunkName: "CoBrandingBannerMobile" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes(
    'CoBrandingBannerMobile',
    UiTypes,
  ),
};

export default entry;
