import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  ComboBoxInputNavigation: () => [
    import(
      './viewer/skinComps/ComboBoxInputNavigation/ComboBoxInputNavigation.skin' /* webpackChunkName: "DropDownMenu_ComboBoxInputNavigation" */
    ),
    import(
      './viewer/DropDownMenuComboBox.controller' /* webpackChunkName: "DropDownMenu_ComboBoxInputNavigation" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('DropDownMenu', UiTypes),
};

export default entry;
