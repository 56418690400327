import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  MatrixGalleryDefaultSkin: () => [
    import(
      './viewer/skinComps/MatrixGalleryDefaultSkin/MatrixGalleryDefaultSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryDefaultSkin" */
    ),
    import(
      './viewer/MatrixGallery.controller' /* webpackChunkName: "MatrixGallery_MatrixGalleryDefaultSkin" */
    ),
  ],
  MatrixGalleryTextSlideUpSkin: () => [
    import(
      './viewer/skinComps/MatrixGalleryTextSlideUpSkin/MatrixGalleryTextSlideUpSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryTextSlideUpSkin" */
    ),
    import(
      './viewer/MatrixGallery.controller' /* webpackChunkName: "MatrixGallery_MatrixGalleryTextSlideUpSkin" */
    ),
  ],
  MatrixGalleryTextOnCenterSkin: () => [
    import(
      './viewer/skinComps/MatrixGalleryTextOnCenterSkin/MatrixGalleryTextOnCenterSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryTextOnCenterSkin" */
    ),
    import(
      './viewer/MatrixGallery.controller' /* webpackChunkName: "MatrixGallery_MatrixGalleryTextOnCenterSkin" */
    ),
  ],
  MatrixGalleryCircleSkin: () => [
    import(
      './viewer/skinComps/MatrixGalleryCircleSkin/MatrixGalleryCircleSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryCircleSkin" */
    ),
    import(
      './viewer/MatrixGallery.controller' /* webpackChunkName: "MatrixGallery_MatrixGalleryCircleSkin" */
    ),
  ],
  MatrixGalleryLiftedShadow: () => [
    import(
      './viewer/skinComps/MatrixGalleryLiftedShadow/MatrixGalleryLiftedShadow.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryLiftedShadow" */
    ),
    import(
      './viewer/MatrixGallery.controller' /* webpackChunkName: "MatrixGallery_MatrixGalleryLiftedShadow" */
    ),
  ],
  MatrixGalleryTransparentSkin: () => [
    import(
      './viewer/skinComps/MatrixGalleryTransparentSkin/MatrixGalleryTransparentSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryTransparentSkin" */
    ),
    import(
      './viewer/MatrixGallery.controller' /* webpackChunkName: "MatrixGallery_MatrixGalleryTransparentSkin" */
    ),
  ],
  PolaroidCustomHeightSkin: () => [
    import(
      './viewer/skinComps/PolaroidCustomHeightSkin/PolaroidCustomHeightSkin.skin' /* webpackChunkName: "MatrixGallery_PolaroidCustomHeightSkin" */
    ),
    import(
      './viewer/MatrixGallery.controller' /* webpackChunkName: "MatrixGallery_PolaroidCustomHeightSkin" */
    ),
  ],
  MatrixGalleryTextBelowSkin: () => [
    import(
      './viewer/skinComps/MatrixGalleryTextBelowSkin/MatrixGalleryTextBelowSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryTextBelowSkin" */
    ),
    import(
      './viewer/MatrixGallery.controller' /* webpackChunkName: "MatrixGallery_MatrixGalleryTextBelowSkin" */
    ),
  ],
  BlogMatrixGallery: () => [
    import(
      './viewer/skinComps/BlogMatrixGallery/BlogMatrixGallery.skin' /* webpackChunkName: "MatrixGallery_BlogMatrixGallery" */
    ),
    import(
      './viewer/MatrixGallery.controller' /* webpackChunkName: "MatrixGallery_BlogMatrixGallery" */
    ),
  ],
  MatrixGalleryPolaroidSkin: () => [
    import(
      './viewer/skinComps/MatrixGalleryPolaroidSkin/MatrixGalleryPolaroidSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryPolaroidSkin" */
    ),
    import(
      './viewer/MatrixGallery.controller' /* webpackChunkName: "MatrixGallery_MatrixGalleryPolaroidSkin" */
    ),
  ],
  MatrixGalleryScotchTapeSkin: () => [
    import(
      './viewer/skinComps/MatrixGalleryScotchTapeSkin/MatrixGalleryScotchTapeSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryScotchTapeSkin" */
    ),
    import(
      './viewer/MatrixGallery.controller' /* webpackChunkName: "MatrixGallery_MatrixGalleryScotchTapeSkin" */
    ),
  ],
  MatrixGalleryIronSkin: () => [
    import(
      './viewer/skinComps/MatrixGalleryIronSkin/MatrixGalleryIronSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryIronSkin" */
    ),
    import(
      './viewer/MatrixGallery.controller' /* webpackChunkName: "MatrixGallery_MatrixGalleryIronSkin" */
    ),
  ],
  MatrixGallerySeparateTextBoxSkin: () => [
    import(
      './viewer/skinComps/MatrixGallerySeparateTextBoxSkin/MatrixGallerySeparateTextBoxSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGallerySeparateTextBoxSkin" */
    ),
    import(
      './viewer/MatrixGallery.controller' /* webpackChunkName: "MatrixGallery_MatrixGallerySeparateTextBoxSkin" */
    ),
  ],
  MatrixGallerySloopy: () => [
    import(
      './viewer/skinComps/MatrixGallerySloopy/MatrixGallerySloopy.skin' /* webpackChunkName: "MatrixGallery_MatrixGallerySloopy" */
    ),
    import(
      './viewer/MatrixGallery.controller' /* webpackChunkName: "MatrixGallery_MatrixGallerySloopy" */
    ),
  ],
  TextBottomCustomHeightSkin: () => [
    import(
      './viewer/skinComps/TextBottomCustomHeightSkin/TextBottomCustomHeightSkin.skin' /* webpackChunkName: "MatrixGallery_TextBottomCustomHeightSkin" */
    ),
    import(
      './viewer/MatrixGallery.controller' /* webpackChunkName: "MatrixGallery_TextBottomCustomHeightSkin" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('MatrixGallery', UiTypes),
};

export default entry;
