import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import('./viewer/Collage' /* webpackChunkName: "Collage" */),
    import('./viewer/Collage.controller' /* webpackChunkName: "Collage" */),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('Collage', UiTypes),
};

export default entry;
