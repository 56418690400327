import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  EPlayerFramedPlay: () => [
    import(
      './viewer/skinComps/EPlayerFramedPlay/EPlayerFramedPlay.skin' /* webpackChunkName: "SingleAudioPlayer_EPlayerFramedPlay" */
    ),
  ],
  EPlayerLargePlay: () => [
    import(
      './viewer/skinComps/EPlayerLargePlay/EPlayerLargePlay.skin' /* webpackChunkName: "SingleAudioPlayer_EPlayerLargePlay" */
    ),
  ],
  EPlayerRoundPlay: () => [
    import(
      './viewer/skinComps/EPlayerRoundPlay/EPlayerRoundPlay.skin' /* webpackChunkName: "SingleAudioPlayer_EPlayerRoundPlay" */
    ),
  ],
  SingleAudioPlayerSkin: () => [
    import(
      './viewer/skinComps/SingleAudioPlayerSkin/SingleAudioPlayerSkin.skin' /* webpackChunkName: "SingleAudioPlayer_SingleAudioPlayerSkin" */
    ),
  ],
  MusicPlayer: () => [
    import(
      './viewer/skinComps/MusicPlayer/MusicPlayer.skin' /* webpackChunkName: "SingleAudioPlayer_MusicPlayer" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('SingleAudioPlayer', UiTypes),
};

export default entry;
