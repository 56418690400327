import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  SliderGalleryDefaultSkin: () => [
    import(
      './viewer/skinComps/SliderGalleryDefaultSkin/SliderGalleryDefaultSkin.skin' /* webpackChunkName: "SliderGallery_SliderGalleryDefaultSkin" */
    ),
  ],
  SliderGalleryCircleSkin: () => [
    import(
      './viewer/skinComps/SliderGalleryCircleSkin/SliderGalleryCircleSkin.skin' /* webpackChunkName: "SliderGallery_SliderGalleryCircleSkin" */
    ),
  ],
  SliderGalleryScotchTapeSkin: () => [
    import(
      './viewer/skinComps/SliderGalleryScotchTapeSkin/SliderGalleryScotchTapeSkin.skin' /* webpackChunkName: "SliderGallery_SliderGalleryScotchTapeSkin" */
    ),
  ],
  SliderGalleryNoArrow: () => [
    import(
      './viewer/skinComps/SliderGalleryNoArrow/SliderGalleryNoArrow.skin' /* webpackChunkName: "SliderGallery_SliderGalleryNoArrow" */
    ),
  ],
  SliderGalleryIronSkin: () => [
    import(
      './viewer/skinComps/SliderGalleryIronSkin/SliderGalleryIronSkin.skin' /* webpackChunkName: "SliderGallery_SliderGalleryIronSkin" */
    ),
  ],
  SliderGalleryMinimal: () => [
    import(
      './viewer/skinComps/SliderGalleryMinimal/SliderGalleryMinimal.skin' /* webpackChunkName: "SliderGallery_SliderGalleryMinimal" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('SliderGallery', UiTypes),
};

export default entry;
