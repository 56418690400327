import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import('./viewer/StripShowcase' /* webpackChunkName: "StripShowcase" */),
    import(
      './viewer/StripShowcase.controller' /* webpackChunkName: "StripShowcase" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('StripShowcase', UiTypes),
};

export default entry;
