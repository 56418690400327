import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import('./viewer/SignatureInput' /* webpackChunkName: "SignatureInput" */),
    import(
      './viewer/SignatureInput.controller' /* webpackChunkName: "SignatureInput" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('SignatureInput', UiTypes),
};

export default entry;
