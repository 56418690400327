import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import('./viewer/DatePicker' /* webpackChunkName: "DatePicker" */),
    import(
      './viewer/DatePicker.controller' /* webpackChunkName: "DatePicker" */
    ),
  ],
  DatePickerDefaultSkin: () => [
    import(
      './viewer/skinComps/DatePickerDefaultSkin/DatePickerDefaultSkin.skin' /* webpackChunkName: "DatePicker_DatePickerDefaultSkin" */
    ),
    import(
      './viewer/DatePicker.controller' /* webpackChunkName: "DatePicker_DatePickerDefaultSkin" */
    ),
  ],
  DatePickerTextBetweenNavSkin: () => [
    import(
      './viewer/skinComps/DatePickerTextBetweenNavSkin/DatePickerTextBetweenNavSkin.skin' /* webpackChunkName: "DatePicker_DatePickerTextBetweenNavSkin" */
    ),
    import(
      './viewer/DatePicker.controller' /* webpackChunkName: "DatePicker_DatePickerTextBetweenNavSkin" */
    ),
  ],
  DatePickerTextYearNavSkin: () => [
    import(
      './viewer/skinComps/DatePickerTextYearNavSkin/DatePickerTextYearNavSkin.skin' /* webpackChunkName: "DatePicker_DatePickerTextYearNavSkin" */
    ),
    import(
      './viewer/DatePicker.controller' /* webpackChunkName: "DatePicker_DatePickerTextYearNavSkin" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('DatePicker', UiTypes),
};

export default entry;
