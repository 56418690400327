import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  ArrowLine: () => [
    import(
      './viewer/skinComps/ArrowLine/ArrowLine.skin' /* webpackChunkName: "FiveGridLine_ArrowLine" */
    ),
  ],
  ArrowRightLine: () => [
    import(
      './viewer/skinComps/ArrowRightLine/ArrowRightLine.skin' /* webpackChunkName: "FiveGridLine_ArrowRightLine" */
    ),
  ],
  FiveGridLineSkin: () => [
    import(
      './viewer/skinComps/FiveGridLineSkin/FiveGridLineSkin.skin' /* webpackChunkName: "FiveGridLine_FiveGridLineSkin" */
    ),
  ],
  DashedLine: () => [
    import(
      './viewer/skinComps/DashedLine/DashedLine.skin' /* webpackChunkName: "FiveGridLine_DashedLine" */
    ),
  ],
  DottedLine: () => [
    import(
      './viewer/skinComps/DottedLine/DottedLine.skin' /* webpackChunkName: "FiveGridLine_DottedLine" */
    ),
  ],
  DoubleLine: () => [
    import(
      './viewer/skinComps/DoubleLine/DoubleLine.skin' /* webpackChunkName: "FiveGridLine_DoubleLine" */
    ),
  ],
  DoubleLine2: () => [
    import(
      './viewer/skinComps/DoubleLine2/DoubleLine2.skin' /* webpackChunkName: "FiveGridLine_DoubleLine2" */
    ),
  ],
  DoubleLine3: () => [
    import(
      './viewer/skinComps/DoubleLine3/DoubleLine3.skin' /* webpackChunkName: "FiveGridLine_DoubleLine3" */
    ),
  ],
  FadeLine: () => [
    import(
      './viewer/skinComps/FadeLine/FadeLine.skin' /* webpackChunkName: "FiveGridLine_FadeLine" */
    ),
  ],
  FadeNotchBottomLine: () => [
    import(
      './viewer/skinComps/FadeNotchBottomLine/FadeNotchBottomLine.skin' /* webpackChunkName: "FiveGridLine_FadeNotchBottomLine" */
    ),
  ],
  FadeNotchTopLine: () => [
    import(
      './viewer/skinComps/FadeNotchTopLine/FadeNotchTopLine.skin' /* webpackChunkName: "FiveGridLine_FadeNotchTopLine" */
    ),
  ],
  FiveGridLine: () => [
    import(
      './viewer/skinComps/FiveGridLine/FiveGridLine.skin' /* webpackChunkName: "FiveGridLine_FiveGridLine" */
    ),
  ],
  IronLine: () => [
    import(
      './viewer/skinComps/IronLine/IronLine.skin' /* webpackChunkName: "FiveGridLine_IronLine" */
    ),
  ],
  NotchDashedLine: () => [
    import(
      './viewer/skinComps/NotchDashedLine/NotchDashedLine.skin' /* webpackChunkName: "FiveGridLine_NotchDashedLine" */
    ),
  ],
  NotchLine: () => [
    import(
      './viewer/skinComps/NotchLine/NotchLine.skin' /* webpackChunkName: "FiveGridLine_NotchLine" */
    ),
  ],
  ShadowBottomLine: () => [
    import(
      './viewer/skinComps/ShadowBottomLine/ShadowBottomLine.skin' /* webpackChunkName: "FiveGridLine_ShadowBottomLine" */
    ),
  ],
  ShadowTopLine: () => [
    import(
      './viewer/skinComps/ShadowTopLine/ShadowTopLine.skin' /* webpackChunkName: "FiveGridLine_ShadowTopLine" */
    ),
  ],
  SloppyLine: () => [
    import(
      './viewer/skinComps/SloppyLine/SloppyLine.skin' /* webpackChunkName: "FiveGridLine_SloppyLine" */
    ),
  ],
  SolidLine: () => [
    import(
      './viewer/skinComps/SolidLine/SolidLine.skin' /* webpackChunkName: "FiveGridLine_SolidLine" */
    ),
  ],
  ZigzagLineFlipSkin: () => [
    import(
      './viewer/skinComps/ZigzagLineFlipSkin/ZigzagLineFlipSkin.skin' /* webpackChunkName: "FiveGridLine_ZigzagLineFlipSkin" */
    ),
  ],
  ZigzagLineSkin: () => [
    import(
      './viewer/skinComps/ZigzagLineSkin/ZigzagLineSkin.skin' /* webpackChunkName: "FiveGridLine_ZigzagLineSkin" */
    ),
  ],
  SkinNotFound: () => [
    import(
      './viewer/skinComps/SkinNotFound/SkinNotFound.skin' /* webpackChunkName: "FiveGridLine_SkinNotFound" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('FiveGridLine', UiTypes),
};

export default entry;
