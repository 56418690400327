import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import(
      './viewer/skinComps/VerticalMenuSeparatedButtonFixedWidthSkin/VerticalMenuSeparatedButtonFixedWidthSkin.skin' /* webpackChunkName: "VerticalMenu" */
    ),
  ],
  VerticalMenuSeparatedButtonFixedWidthSkin: () => [
    import(
      './viewer/skinComps/VerticalMenuSeparatedButtonFixedWidthSkin/VerticalMenuSeparatedButtonFixedWidthSkin.skin' /* webpackChunkName: "VerticalMenu_VerticalMenuSeparatedButtonFixedWidthSkin" */
    ),
  ],
  VerticalMenuTextWithSeparatorsSkin: () => [
    import(
      './viewer/skinComps/VerticalMenuTextWithSeparatorsSkin/VerticalMenuTextWithSeparatorsSkin.skin' /* webpackChunkName: "VerticalMenu_VerticalMenuTextWithSeparatorsSkin" */
    ),
  ],
  VerticalMenuTextSkin: () => [
    import(
      './viewer/skinComps/VerticalMenuTextSkin/VerticalMenuTextSkin.skin' /* webpackChunkName: "VerticalMenu_VerticalMenuTextSkin" */
    ),
  ],
  VerticalMenuSolidColorSkin: () => [
    import(
      './viewer/skinComps/VerticalMenuSolidColorSkin/VerticalMenuSolidColorSkin.skin' /* webpackChunkName: "VerticalMenu_VerticalMenuSolidColorSkin" */
    ),
  ],
  VerticalMenuSeparatedButtonSkin: () => [
    import(
      './viewer/skinComps/VerticalMenuSeparatedButtonSkin/VerticalMenuSeparatedButtonSkin.skin' /* webpackChunkName: "VerticalMenu_VerticalMenuSeparatedButtonSkin" */
    ),
  ],
  VerticalMenuArrowSkin: () => [
    import(
      './viewer/skinComps/VerticalMenuArrowSkin/VerticalMenuArrowSkin.skin' /* webpackChunkName: "VerticalMenu_VerticalMenuArrowSkin" */
    ),
  ],
  VerticalMenuRibbonsSkin: () => [
    import(
      './viewer/skinComps/VerticalMenuRibbonsSkin/VerticalMenuRibbonsSkin.skin' /* webpackChunkName: "VerticalMenu_VerticalMenuRibbonsSkin" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('VerticalMenu', UiTypes),
};

export default entry;
