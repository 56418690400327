import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import('./viewer/RadioGroup' /* webpackChunkName: "RadioGroup" */),
    import(
      './viewer/RadioGroup.controller' /* webpackChunkName: "RadioGroup" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('RadioGroup', UiTypes),
};

export default entry;
