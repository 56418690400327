import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import(
      './FreemiumBannerDesktop' /* webpackChunkName: "FreemiumBannerDesktop" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('FreemiumBannerDesktop', UiTypes),
};

export default entry;
