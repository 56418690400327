import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import(
      // We want it to use the same chunk name as the main RichTextBox component
      './viewer/RichTextBoxLinkModal' /* webpackChunkName: "RichTextBox" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('RichTextBoxLinkModal', UiTypes),
};

export default entry;
