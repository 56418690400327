import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  ComboBoxInputNavigation: () => [
    import(
      './viewer/skinComps/ComboBoxInputNavigation/ComboBoxInputNavigation.skin' /* webpackChunkName: "VerticalMenu_ComboBoxInputNavigation" */
    ),
    import(
      './viewer/VerticalMenuComboBox.controller' /* webpackChunkName: "VerticalMenu_ComboBoxInputNavigation" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('VerticalMenu', UiTypes),
};

export default entry;
