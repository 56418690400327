import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import(
      './viewer/BackToTopButton' /* webpackChunkName: "BackToTopButton" */
    ),
    import(
      './viewer/BackToTopButton.controller' /* webpackChunkName: "BackToTopButton" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('BackToTopButton', UiTypes),
};

export default entry;
