import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  AppleArea: () => [
    import(
      './viewer/skinComps/AppleArea/AppleArea.skin' /* webpackChunkName: "Container_AppleArea" */
    ),
  ],
  ArrowRightRibbon: () => [
    import(
      './viewer/skinComps/ArrowRightRibbon/ArrowRightRibbon.skin' /* webpackChunkName: "Container_ArrowRightRibbon" */
    ),
  ],
  BlankAreaSkin: () => [
    import(
      './viewer/skinComps/BlankAreaSkin/BlankAreaSkin.skin' /* webpackChunkName: "Container_BlankAreaSkin" */
    ),
  ],
  BorderDashDefaultAreaSkin: () => [
    import(
      './viewer/skinComps/BorderDashDefaultAreaSkin/BorderDashDefaultAreaSkin.skin' /* webpackChunkName: "Container_BorderDashDefaultAreaSkin" */
    ),
  ],
  CircleArea: () => [
    import(
      './viewer/skinComps/CircleArea/CircleArea.skin' /* webpackChunkName: "Container_CircleArea" */
    ),
  ],
  CleanZoomAreaSkin: () => [
    import(
      './viewer/skinComps/CleanZoomAreaSkin/CleanZoomAreaSkin.skin' /* webpackChunkName: "Container_CleanZoomAreaSkin" */
    ),
  ],
  DBDefaultAreaSkin: () => [
    import(
      './viewer/skinComps/DBDefaultAreaSkin/DBDefaultAreaSkin.skin' /* webpackChunkName: "Container_DBDefaultAreaSkin" */
    ),
  ],
  DefaultAreaSkin: () => [
    import(
      './viewer/skinComps/DefaultAreaSkin/DefaultAreaSkin.skin' /* webpackChunkName: "Container_DefaultAreaSkin" */
    ),
  ],
  GridArea: () => [
    import(
      './viewer/skinComps/GridArea/GridArea.skin' /* webpackChunkName: "Container_GridArea" */
    ),
  ],
  InnerMarginAreaSkin: () => [
    import(
      './viewer/skinComps/InnerMarginAreaSkin/InnerMarginAreaSkin.skin' /* webpackChunkName: "Container_InnerMarginAreaSkin" */
    ),
  ],
  InnerShadowAreaSkin: () => [
    import(
      './viewer/skinComps/InnerShadowAreaSkin/InnerShadowAreaSkin.skin' /* webpackChunkName: "Container_InnerShadowAreaSkin" */
    ),
  ],
  LinesAreaSkin: () => [
    import(
      './viewer/skinComps/LinesAreaSkin/LinesAreaSkin.skin' /* webpackChunkName: "Container_LinesAreaSkin" */
    ),
  ],
  RectangleArea: () => [
    import(
      './viewer/skinComps/RectangleArea/RectangleArea.skin' /* webpackChunkName: "Container_RectangleArea" */
    ),
  ],
  RectangleAreaAfterScroll: () => [
    import(
      './viewer/skinComps/RectangleAreaAfterScroll/RectangleAreaAfterScroll.skin' /* webpackChunkName: "Container_RectangleAreaAfterScroll" */
    ),
  ],
  RoundArea: () => [
    import(
      './viewer/skinComps/RoundArea/RoundArea.skin' /* webpackChunkName: "Container_RoundArea" */
    ),
  ],
  RoundShadowArea: () => [
    import(
      './viewer/skinComps/RoundShadowArea/RoundShadowArea.skin' /* webpackChunkName: "Container_RoundShadowArea" */
    ),
  ],
  ThreeDeeAreaSkin: () => [
    import(
      './viewer/skinComps/ThreeDeeAreaSkin/ThreeDeeAreaSkin.skin' /* webpackChunkName: "Container_ThreeDeeAreaSkin" */
    ),
  ],
  TiltedAreaSkin: () => [
    import(
      './viewer/skinComps/TiltedAreaSkin/TiltedAreaSkin.skin' /* webpackChunkName: "Container_TiltedAreaSkin" */
    ),
  ],
  TransparentArea: () => [
    import(
      './viewer/skinComps/TransparentArea/TransparentArea.skin' /* webpackChunkName: "Container_TransparentArea" */
    ),
  ],
  WrapperSkin: () => [
    import(
      './viewer/skinComps/WrapperSkin/WrapperSkin.skin' /* webpackChunkName: "Container_WrapperSkin" */
    ),
  ],
  BubbleArea: () => [
    import(
      './viewer/skinComps/BubbleArea/BubbleArea.skin' /* webpackChunkName: "Container_BubbleArea" */
    ),
  ],
  BubbleAreaLeft: () => [
    import(
      './viewer/skinComps/BubbleAreaLeft/BubbleAreaLeft.skin' /* webpackChunkName: "Container_BubbleAreaLeft" */
    ),
  ],
  BubbleAreaRight: () => [
    import(
      './viewer/skinComps/BubbleAreaRight/BubbleAreaRight.skin' /* webpackChunkName: "Container_BubbleAreaRight" */
    ),
  ],
  BubbleLeftArea: () => [
    import(
      './viewer/skinComps/BubbleLeftArea/BubbleLeftArea.skin' /* webpackChunkName: "Container_BubbleLeftArea" */
    ),
  ],
  LeftTriangleArea: () => [
    import(
      './viewer/skinComps/LeftTriangleArea/LeftTriangleArea.skin' /* webpackChunkName: "Container_LeftTriangleArea" */
    ),
  ],
  RightTriangleArea: () => [
    import(
      './viewer/skinComps/RightTriangleArea/RightTriangleArea.skin' /* webpackChunkName: "Container_RightTriangleArea" */
    ),
  ],
  ScotchDoubleHorizontalArea: () => [
    import(
      './viewer/skinComps/ScotchDoubleHorizontalArea/ScotchDoubleHorizontalArea.skin' /* webpackChunkName: "Container_ScotchDoubleHorizontalArea" */
    ),
  ],
  ScotchDoubleVerticalArea: () => [
    import(
      './viewer/skinComps/ScotchDoubleVerticalArea/ScotchDoubleVerticalArea.skin' /* webpackChunkName: "Container_ScotchDoubleVerticalArea" */
    ),
  ],
  ScotchTopArea: () => [
    import(
      './viewer/skinComps/ScotchTopArea/ScotchTopArea.skin' /* webpackChunkName: "Container_ScotchTopArea" */
    ),
  ],
  CenterRibbon: () => [
    import(
      './viewer/skinComps/CenterRibbon/CenterRibbon.skin' /* webpackChunkName: "Container_CenterRibbon" */
    ),
  ],
  CustomRibbonArea: () => [
    import(
      './viewer/skinComps/CustomRibbonArea/CustomRibbonArea.skin' /* webpackChunkName: "Container_CustomRibbonArea" */
    ),
  ],
  ForkedRibbonArea: () => [
    import(
      './viewer/skinComps/ForkedRibbonArea/ForkedRibbonArea.skin' /* webpackChunkName: "Container_ForkedRibbonArea" */
    ),
  ],
  ForkedRightRibbon: () => [
    import(
      './viewer/skinComps/ForkedRightRibbon/ForkedRightRibbon.skin' /* webpackChunkName: "Container_ForkedRightRibbon" */
    ),
  ],
  ResponsiveBox: () => [
    import(
      './viewer/skinComps/ResponsiveBox/ResponsiveBox.skin' /* webpackChunkName: "Container_ResponsiveBox" */
    ),
  ],
  IronBox: () => [
    import(
      './viewer/skinComps/IronBox/IronBox.skin' /* webpackChunkName: "Container_IronBox" */
    ),
  ],
  LiftedBottomAreaSkin: () => [
    import(
      './viewer/skinComps/LiftedBottomAreaSkin/LiftedBottomAreaSkin.skin' /* webpackChunkName: "Container_LiftedBottomAreaSkin" */
    ),
  ],
  LiftedShadowArea: () => [
    import(
      './viewer/skinComps/LiftedShadowArea/LiftedShadowArea.skin' /* webpackChunkName: "Container_LiftedShadowArea" */
    ),
  ],
  LiftedTopAreaSkin: () => [
    import(
      './viewer/skinComps/LiftedTopAreaSkin/LiftedTopAreaSkin.skin' /* webpackChunkName: "Container_LiftedTopAreaSkin" */
    ),
  ],
  PhotoArea: () => [
    import(
      './viewer/skinComps/PhotoArea/PhotoArea.skin' /* webpackChunkName: "Container_PhotoArea" */
    ),
  ],
  RibbonAreaSkin: () => [
    import(
      './viewer/skinComps/RibbonAreaSkin/RibbonAreaSkin.skin' /* webpackChunkName: "Container_RibbonAreaSkin" */
    ),
  ],
  SandClockArea: () => [
    import(
      './viewer/skinComps/SandClockArea/SandClockArea.skin' /* webpackChunkName: "Container_SandClockArea" */
    ),
  ],
  SloopyArea: () => [
    import(
      './viewer/skinComps/SloopyArea/SloopyArea.skin' /* webpackChunkName: "Container_SloopyArea" */
    ),
  ],
  VerticalArrowArea: () => [
    import(
      './viewer/skinComps/VerticalArrowArea/VerticalArrowArea.skin' /* webpackChunkName: "Container_VerticalArrowArea" */
    ),
  ],
  VerticalRibbonArea: () => [
    import(
      './viewer/skinComps/VerticalRibbonArea/VerticalRibbonArea.skin' /* webpackChunkName: "Container_VerticalRibbonArea" */
    ),
  ],
  '': () => [import('./viewer/Container' /* webpackChunkName: "Container" */)],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('Container', UiTypes),
};

export default entry;
