import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  BasicButton: () => [
    import(
      './viewer/skinComps/BaseButton/BasicButton.skin' /* webpackChunkName: "SiteButton_BasicButton" */
    ),
  ],
  TextOnlyButtonSkin: () => [
    import(
      './viewer/skinComps/BaseButton/TextOnlyButtonSkin.skin' /* webpackChunkName: "SiteButton_TextOnlyButtonSkin" */
    ),
  ],
  AddProductButton: () => [
    import(
      './viewer/skinComps/BaseButton/AddProductButton.skin' /* webpackChunkName: "SiteButton_AddProductButton" */
    ),
  ],
  ApplyButtonEcom: () => [
    import(
      './viewer/skinComps/BaseButton/ApplyButtonEcom.skin' /* webpackChunkName: "SiteButton_ApplyButtonEcom" */
    ),
  ],
  BorderButton: () => [
    import(
      './viewer/skinComps/BaseButton/BorderButton.skin' /* webpackChunkName: "SiteButton_BorderButton" */
    ),
  ],
  BrandButton: () => [
    import(
      './viewer/skinComps/BaseButton/BrandButton.skin' /* webpackChunkName: "SiteButton_BrandButton" */
    ),
  ],
  ButtonInnerShadow: () => [
    import(
      './viewer/skinComps/BaseButton/ButtonInnerShadow.skin' /* webpackChunkName: "SiteButton_ButtonInnerShadow" */
    ),
  ],
  ButtonShadowLeft: () => [
    import(
      './viewer/skinComps/BaseButton/ButtonShadowLeft.skin' /* webpackChunkName: "SiteButton_ButtonShadowLeft" */
    ),
  ],
  ButtonShadowRight: () => [
    import(
      './viewer/skinComps/BaseButton/ButtonShadowRight.skin' /* webpackChunkName: "SiteButton_ButtonShadowRight" */
    ),
  ],
  ButtonThreeD: () => [
    import(
      './viewer/skinComps/BaseButton/ButtonThreeD.skin' /* webpackChunkName: "SiteButton_ButtonThreeD" */
    ),
  ],
  CircleButton: () => [
    import(
      './viewer/skinComps/BaseButton/CircleButton.skin' /* webpackChunkName: "SiteButton_CircleButton" */
    ),
  ],
  DisabledLayerButton: () => [
    import(
      './viewer/skinComps/BaseButton/DisabledLayerButton.skin' /* webpackChunkName: "SiteButton_DisabledLayerButton" */
    ),
  ],
  EcomFeedbackCheckoutButton: () => [
    import(
      './viewer/skinComps/BaseButton/EcomFeedbackCheckoutButton.skin' /* webpackChunkName: "SiteButton_EcomFeedbackCheckoutButton" */
    ),
  ],
  EcomFeedbackContinueShopping: () => [
    import(
      './viewer/skinComps/BaseButton/EcomFeedbackContinueShopping.skin' /* webpackChunkName: "SiteButton_EcomFeedbackContinueShopping" */
    ),
  ],
  EcomFeedbackContinueShopping2: () => [
    import(
      './viewer/skinComps/BaseButton/EcomFeedbackContinueShopping2.skin' /* webpackChunkName: "SiteButton_EcomFeedbackContinueShopping2" */
    ),
  ],
  FixedFontButton: () => [
    import(
      './viewer/skinComps/BaseButton/FixedFontButton.skin' /* webpackChunkName: "SiteButton_FixedFontButton" */
    ),
  ],
  PillButton: () => [
    import(
      './viewer/skinComps/BaseButton/PillButton.skin' /* webpackChunkName: "SiteButton_PillButton" */
    ),
  ],
  ShineButton: () => [
    import(
      './viewer/skinComps/BaseButton/ShineButton.skin' /* webpackChunkName: "SiteButton_ShineButton" */
    ),
  ],
  ShinyButtonIISkin: () => [
    import(
      './viewer/skinComps/BaseButton/ShinyButtonIISkin.skin' /* webpackChunkName: "SiteButton_ShinyButtonIISkin" */
    ),
  ],
  ShinyButtonISkin: () => [
    import(
      './viewer/skinComps/BaseButton/ShinyButtonISkin.skin' /* webpackChunkName: "SiteButton_ShinyButtonISkin" */
    ),
  ],
  ShinyPillButton: () => [
    import(
      './viewer/skinComps/BaseButton/ShinyPillButton.skin' /* webpackChunkName: "SiteButton_ShinyPillButton" */
    ),
  ],
  SiteButtonBlueSkin: () => [
    import(
      './viewer/skinComps/BaseButton/SiteButtonBlueSkin.skin' /* webpackChunkName: "SiteButton_SiteButtonBlueSkin" */
    ),
  ],
  SiteButtonSkin: () => [
    import(
      './viewer/skinComps/BaseButton/SiteButtonSkin.skin' /* webpackChunkName: "SiteButton_SiteButtonSkin" */
    ),
  ],
  WrappingButton: () => [
    import(
      './viewer/skinComps/BaseButton/WrappingButton.skin' /* webpackChunkName: "SiteButton_WrappingButton" */
    ),
  ],
  ButtonArrow: () => [
    import(
      './viewer/skinComps/ButtonArrow/ButtonArrow.skin' /* webpackChunkName: "SiteButton_ButtonArrow" */
    ),
  ],
  ButtonArrowLeft: () => [
    import(
      './viewer/skinComps/ButtonArrow/ButtonArrowLeft.skin' /* webpackChunkName: "SiteButton_ButtonArrowLeft" */
    ),
  ],
  ButtonDoubleArrowLeft: () => [
    import(
      './viewer/skinComps/ButtonDoubleArrowLeft/ButtonDoubleArrowLeft.skin' /* webpackChunkName: "SiteButton_ButtonDoubleArrowLeft" */
    ),
  ],
  ButtonDoubleArrowRight: () => [
    import(
      './viewer/skinComps/ButtonDoubleArrowRight/ButtonDoubleArrowRight.skin' /* webpackChunkName: "SiteButton_ButtonDoubleArrowRight" */
    ),
  ],
  ButtonForkedLeft: () => [
    import(
      './viewer/skinComps/ButtonForked/ButtonForkedLeft.skin' /* webpackChunkName: "SiteButton_ButtonForkedLeft" */
    ),
  ],
  ButtonForkedRight: () => [
    import(
      './viewer/skinComps/ButtonForked/ButtonForkedRight.skin' /* webpackChunkName: "SiteButton_ButtonForkedRight" */
    ),
  ],
  ButtonLiftedShadow: () => [
    import(
      './viewer/skinComps/ButtonLiftedShadow/ButtonLiftedShadow.skin' /* webpackChunkName: "SiteButton_ButtonLiftedShadow" */
    ),
  ],
  ButtonSandclock: () => [
    import(
      './viewer/skinComps/ButtonSandclock/ButtonSandclock.skin' /* webpackChunkName: "SiteButton_ButtonSandclock" */
    ),
  ],
  GamingButton: () => [
    import(
      './viewer/skinComps/GamingButton/GamingButton.skin' /* webpackChunkName: "SiteButton_GamingButton" */
    ),
  ],
  IronButton: () => [
    import(
      './viewer/skinComps/IronButton/IronButton.skin' /* webpackChunkName: "SiteButton_IronButton" */
    ),
  ],
  RibbonButton: () => [
    import(
      './viewer/skinComps/RibbonButton/RibbonButton.skin' /* webpackChunkName: "SiteButton_RibbonButton" */
    ),
  ],
  ScotchTapeButton: () => [
    import(
      './viewer/skinComps/ScotchTapeButton/ScotchTapeButton.skin' /* webpackChunkName: "SiteButton_ScotchTapeButton" */
    ),
  ],
  ShinyButtonInverted: () => [
    import(
      './viewer/skinComps/ShinyButtonInverted/ShinyButtonInverted.skin' /* webpackChunkName: "SiteButton_ShinyButtonInverted" */
    ),
  ],
  ShinyGradientButton: () => [
    import(
      './viewer/skinComps/ShinyGradientButton/ShinyGradientButton.skin' /* webpackChunkName: "SiteButton_ShinyGradientButton" */
    ),
  ],
  SloopyButton: () => [
    import(
      './viewer/skinComps/SloopyButton/SloopyButton.skin' /* webpackChunkName: "SiteButton_SloopyButton" */
    ),
  ],
  ViewerButtonBlueSkin: () => [
    import(
      './viewer/skinComps/ViewerButtonBlueSkin/ViewerButtonBlueSkin.skin' /* webpackChunkName: "SiteButton_ViewerButtonBlueSkin" */
    ),
  ],
  PlasticButton: () => [
    import(
      './viewer/skinComps/PlasticButton/PlasticButton.skin' /* webpackChunkName: "SiteButton_PlasticButton" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('SiteButton', UiTypes),
};

export default entry;
