import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import(
      './viewer/SelectionTagsList' /* webpackChunkName: "SelectionTagsList" */
    ),
    import(
      './viewer/SelectionTagsList.controller' /* webpackChunkName: "SelectionTagsList" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('SelectionTagsList', UiTypes),
};

export default entry;
