import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  TransparentScreen: () => [
    import(
      /* webpackChunkName: "FooterContainer_TransparentScreen" */ './viewer/skinComps/BaseScreen/TransparentScreen.skin'
    ),
  ],
  DefaultScreen: () => [
    import(
      /* webpackChunkName: "FooterContainer_DefaultScreen" */ './viewer/skinComps/DefaultScreen/DefaultScreen.skin'
    ),
  ],
  WoodScreen: () => [
    import(
      /* webpackChunkName: "FooterContainer_WoodScreen" */ './viewer/skinComps/BaseScreen/WoodScreen.skin'
    ),
  ],
  LineTopScreen: () => [
    import(
      /* webpackChunkName: "FooterContainer_LineTopScreen" */ './viewer/skinComps/BaseScreen/LineTopScreen.skin'
    ),
  ],
  NoiseScreen: () => [
    import(
      /* webpackChunkName: "FooterContainer_NoiseScreen" */ './viewer/skinComps/BaseScreen/NoiseScreen.skin'
    ),
  ],
  SolidScreen: () => [
    import(
      /* webpackChunkName: "FooterContainer_SolidScreen" */ './viewer/skinComps/BaseScreen/SolidScreen.skin'
    ),
  ],
  BoxScreen: () => [
    import(
      /* webpackChunkName: "FooterContainer_BoxScreen" */ './viewer/skinComps/BaseScreen/BoxScreen.skin'
    ),
  ],
  DoubleBorderScreen: () => [
    import(
      /* webpackChunkName: "FooterContainer_DoubleBorderScreen" */ './viewer/skinComps/DoubleBorderScreen/DoubleBorderScreen.skin'
    ),
  ],
  IronScreen: () => [
    import(
      /* webpackChunkName: "FooterContainer_IronScreen" */ './viewer/skinComps/IronScreen/IronScreen.skin'
    ),
  ],
  ShadowTopScreen: () => [
    import(
      /* webpackChunkName: "FooterContainer_ShadowTopScreen" */ './viewer/skinComps/ShadowTopScreen/ShadowTopScreen.skin'
    ),
  ],
  LiftedBottomScreen: () => [
    import(
      /* webpackChunkName: "FooterContainer_LiftedBottomScreen" */ './viewer/skinComps/LiftedBottomScreen/LiftedBottomScreen.skin'
    ),
  ],
  LiftedTopScreen: () => [
    import(
      /* webpackChunkName: "FooterContainer_LiftedTopScreen" */ './viewer/skinComps/LiftedTopScreen/LiftedTopScreen.skin'
    ),
  ],
  ThreeDeeScreen: () => [
    import(
      /* webpackChunkName: "FooterContainer_ThreeDeeScreen" */ './viewer/skinComps/ThreeDeeScreen/ThreeDeeScreen.skin'
    ),
  ],
  InnerShadowScreen: () => [
    import(
      /* webpackChunkName: "FooterContainer_InnerShadowScreen" */ './viewer/skinComps/InnerShadowScreen/InnerShadowScreen.skin'
    ),
  ],
  BevelScreen: () => [
    import(
      /* webpackChunkName: "FooterContainer_BevelScreen" */ './viewer/skinComps/BevelScreen/BevelScreen.skin'
    ),
  ],
  BlankScreen: () => [
    import(
      /* webpackChunkName: "FooterContainer_BlankScreen" */ './viewer/skinComps/BaseScreen/BlankScreen.skin'
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('FooterContainer', UiTypes),
};

export default entry;
