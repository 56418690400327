import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import('./viewer/MusicPlayer' /* webpackChunkName: "MusicPlayer" */),
    import(
      './viewer/MusicPlayer.controller' /* webpackChunkName: "MusicPlayer" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('MusicPlayer', UiTypes),
};

export default entry;
