import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import('./viewer/TPA3DCarousel' /* webpackChunkName: "TPA3DCarousel" */),
    import(
      './viewer/TPA3DCarousel.controller' /* webpackChunkName: "TPA3DCarousel" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('TPA3DCarousel', UiTypes),
};

export default entry;
