import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import('./viewer/AudioPlayer' /* webpackChunkName: "AudioPlayer" */),
  ],
  SimplePlayer: () => [
    import(
      './viewer/skinComps/SimplePlayer/SimplePlayer.skin' /* webpackChunkName: "AudioPlayer_SimplePlayer" */
    ),
  ],
  ShinyPlayer: () => [
    import(
      './viewer/skinComps/ShinyPlayer/ShinyPlayer.skin' /* webpackChunkName: "AudioPlayer_ShinyPlayer" */
    ),
  ],
  BoldPlayer: () => [
    import(
      './viewer/skinComps/BoldPlayer/BoldPlayer.skin' /* webpackChunkName: "AudioPlayer_BoldPlayer" */
    ),
  ],
  Audio3DPlayer: () => [
    import(
      './viewer/skinComps/Audio3DPlayer/Audio3DPlayer.skin' /* webpackChunkName: "AudioPlayer_Audio3DPlayer" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('AudioPlayer', UiTypes),
};

export default entry;
