import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  anchoredSkin: () => [
    import(
      './viewer/skinComps/AnchoredSkin/anchoredSkin.skin' /* webpackChunkName: "QuickActionBar_anchoredSkin" */
    ),
    import(
      './viewer/QuickActionBar.controller' /* webpackChunkName: "QuickActionBar_anchoredSkin" */
    ),
  ],
  rectSkin: () => [
    import(
      './viewer/skinComps/RectSkin/rectSkin.skin' /* webpackChunkName: "QuickActionBar_rectSkin" */
    ),
    import(
      './viewer/QuickActionBar.controller' /* webpackChunkName: "QuickActionBar_rectSkin" */
    ),
  ],
  ovalSkin: () => [
    import(
      './viewer/skinComps/OvalSkin/ovalSkin.skin' /* webpackChunkName: "QuickActionBar_ovalSkin" */
    ),
    import(
      './viewer/QuickActionBar.controller' /* webpackChunkName: "QuickActionBar_ovalSkin" */
    ),
  ],
  floatingSkin: () => [
    import(
      './viewer/skinComps/FloatingSkin/floatingSkin.skin' /* webpackChunkName: "QuickActionBar_floatingSkin" */
    ),
    import(
      './viewer/QuickActionBar.controller' /* webpackChunkName: "QuickActionBar_floatingSkin" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('QuickActionBar', UiTypes),
};

export default entry;
