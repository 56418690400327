import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import('./viewer/RatingsInput' /* webpackChunkName: "RatingsInput" */),
    import(
      './viewer/RatingsInput.controller' /* webpackChunkName: "RatingsInput" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('RatingsInput', UiTypes),
};

export default entry;
