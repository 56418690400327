import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  PaginatedGridDefaultSkin: () => [
    import(
      './viewer/skinComps/PaginatedGridDefaultSkin/PaginatedGridDefaultSkin.skin' /* webpackChunkName: "PaginatedGridGallery_PaginatedGridDefaultSkin" */
    ),
    import(
      './viewer/PaginatedGridGallery.controller' /* webpackChunkName: "PaginatedGridGallery_PaginatedGridDefaultSkin" */
    ),
  ],
  PaginatedGridArrowsOutside: () => [
    import(
      './viewer/skinComps/PaginatedGridArrowsOutside/PaginatedGridArrowsOutside.skin' /* webpackChunkName: "PaginatedGridGallery_PaginatedGridArrowsOutside" */
    ),
    import(
      './viewer/PaginatedGridGallery.controller' /* webpackChunkName: "PaginatedGridGallery_PaginatedGridArrowsOutside" */
    ),
  ],
  PaginatedGridRibbonArrows: () => [
    import(
      './viewer/skinComps/PaginatedGridRibbonArrows/PaginatedGridRibbonArrows.skin' /* webpackChunkName: "PaginatedGridGallery_PaginatedGridRibbonArrows" */
    ),
    import(
      './viewer/PaginatedGridGallery.controller' /* webpackChunkName: "PaginatedGridGallery_PaginatedGridRibbonArrows" */
    ),
  ],
  PaginatedGridTextBottom: () => [
    import(
      './viewer/skinComps/PaginatedGridTextBottom/PaginatedGridTextBottom.skin' /* webpackChunkName: "PaginatedGridGallery_PaginatedGridTextBottom" */
    ),
    import(
      './viewer/PaginatedGridGallery.controller' /* webpackChunkName: "PaginatedGridGallery_PaginatedGridTextBottom" */
    ),
  ],
  PaginatedGridOverlay: () => [
    import(
      './viewer/skinComps/PaginatedGridOverlay/PaginatedGridOverlay.skin' /* webpackChunkName: "PaginatedGridGallery_PaginatedGridOverlay" */
    ),
    import(
      './viewer/PaginatedGridGallery.controller' /* webpackChunkName: "PaginatedGridGallery_PaginatedGridOverlay" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('PaginatedGridGallery', UiTypes),
};

export default entry;
