import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import(
      './FreemiumBannerResponsive' /* webpackChunkName: "FreemiumBannerResponsive" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes(
    'FreemiumBannerResponsive',
    UiTypes,
  ),
};

export default entry;
