import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import('./viewer/RefComponent' /* webpackChunkName: "RefComponent" */),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('RefComponent', UiTypes),
};

export default entry;
