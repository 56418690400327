import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [import('./viewer/RSSButton')],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('RSSButton', UiTypes),
};

export default entry;
