import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import('./viewer/DropDownMenu' /* webpackChunkName: "DropDownMenu" */),
  ],
  ArrowRightMenuButtonSkin: () => [
    import(
      './viewer/skinComps/ArrowRightMenuButtonSkin/ArrowRightMenuButtonSkin.skin' /* webpackChunkName: "DropDownMenu_ArrowRightMenuButtonSkin" */
    ),
  ],
  ArrowsMenuSkin: () => [
    import(
      './viewer/skinComps/ArrowsMenuSkin/ArrowsMenuSkin.skin' /* webpackChunkName: "DropDownMenu_ArrowsMenuSkin" */
    ),
  ],
  CirclesMenuSkin: () => [
    import(
      './viewer/skinComps/CirclesMenuSkin/CirclesMenuSkin.skin' /* webpackChunkName: "DropDownMenu_CirclesMenuSkin" */
    ),
  ],
  DiagonalMenuSkin: () => [
    import(
      './viewer/skinComps/DiagonalMenuSkin/DiagonalMenuSkin.skin' /* webpackChunkName: "DropDownMenu_DiagonalMenuSkin" */
    ),
  ],
  IndentedMenuButtonSkin: () => [
    import(
      './viewer/skinComps/IndentedMenuButtonSkin/IndentedMenuButtonSkin.skin' /* webpackChunkName: "DropDownMenu_IndentedMenuButtonSkin" */
    ),
  ],
  LinesMenuButtonBorderRadiusFixSkin: () => [
    import(
      './viewer/skinComps/LinesMenuButton/LinesMenuButtonBorderRadiusFixSkin.skin' /* webpackChunkName: "DropDownMenu_LinesMenuButtonBorderRadiusFixSkin" */
    ),
  ],
  LinesMenuButtonSkin: () => [
    import(
      './viewer/skinComps/LinesMenuButton/LinesMenuButtonSkin.skin' /* webpackChunkName: "DropDownMenu_LinesMenuButtonSkin" */
    ),
  ],
  OverlineMenuButtonHorizontalMenuAdaptationSkin: () => [
    import(
      './viewer/skinComps/OverlineMenuButton/OverlineMenuButtonHorizontalMenuAdaptationSkin.skin' /* webpackChunkName: "DropDownMenu_OverlineMenuButtonHorizontalMenuAdaptationSkin" */
    ),
  ],
  OverlineMenuButtonSkin: () => [
    import(
      './viewer/skinComps/OverlineMenuButton/OverlineMenuButtonSkin.skin' /* webpackChunkName: "DropDownMenu_OverlineMenuButtonSkin" */
    ),
  ],
  PointerMenuButtonHorizontalMenuAdaptationSkin: () => [
    import(
      './viewer/skinComps/PointerMenuButton/PointerMenuButtonHorizontalMenuAdaptationSkin.skin' /* webpackChunkName: "DropDownMenu_PointerMenuButtonHorizontalMenuAdaptationSkin" */
    ),
  ],
  PointerMenuButtonSkin: () => [
    import(
      './viewer/skinComps/PointerMenuButton/PointerMenuButtonSkin.skin' /* webpackChunkName: "DropDownMenu_PointerMenuButtonSkin" */
    ),
  ],
  RibbonsMenuButtonSkin: () => [
    import(
      './viewer/skinComps/RibbonsMenuButtonSkin/RibbonsMenuButtonSkin.skin' /* webpackChunkName: "DropDownMenu_RibbonsMenuButtonSkin" */
    ),
  ],
  SeparateArrowDownMenuSkin: () => [
    import(
      './viewer/skinComps/SeparateArrowDownMenuSkin/SeparateArrowDownMenuSkin.skin' /* webpackChunkName: "DropDownMenu_SeparateArrowDownMenuSkin" */
    ),
  ],
  SeparateBasicMenuButtonSkin: () => [
    import(
      './viewer/skinComps/SeparateBasicMenuButtonSkin/SeparateBasicMenuButtonSkin.skin' /* webpackChunkName: "DropDownMenu_SeparateBasicMenuButtonSkin" */
    ),
  ],
  SeparateIndentedMenuButtonSkin: () => [
    import(
      './viewer/skinComps/SeparateIndentedMenuButtonSkin/SeparateIndentedMenuButtonSkin.skin' /* webpackChunkName: "DropDownMenu_SeparateIndentedMenuButtonSkin" */
    ),
  ],
  SeparateLinesMenuButtonHorizontalMenuAdaptationSkin: () => [
    import(
      './viewer/skinComps/SeparateLinesMenuButton/SeparateLinesMenuButtonHorizontalMenuAdaptationSkin.skin' /* webpackChunkName: "DropDownMenu_SeparateLinesMenuButtonHorizontalMenuAdaptationSkin" */
    ),
  ],
  SeparateLinesMenuButtonSkin: () => [
    import(
      './viewer/skinComps/SeparateLinesMenuButton/SeparateLinesMenuButtonSkin.skin' /* webpackChunkName: "DropDownMenu_SeparateLinesMenuButtonSkin" */
    ),
  ],
  SeparateShinyIIMenuButtonBorderRadiusFixSkin: () => [
    import(
      './viewer/skinComps/SeparateShinyIIMenuButton/SeparateShinyIIMenuButtonBorderRadiusFixSkin.skin' /* webpackChunkName: "DropDownMenu_SeparateShinyIIMenuButtonBorderRadiusFixSkin" */
    ),
  ],
  SeparateShinyIIMenuButtonSkin: () => [
    import(
      './viewer/skinComps/SeparateShinyIIMenuButton/SeparateShinyIIMenuButtonSkin.skin' /* webpackChunkName: "DropDownMenu_SeparateShinyIIMenuButtonSkin" */
    ),
  ],
  SeparateShinyIMenuButtonSkin: () => [
    import(
      './viewer/skinComps/SeparateShinyIMenuButtonSkin/SeparateShinyIMenuButtonSkin.skin' /* webpackChunkName: "DropDownMenu_SeparateShinyIMenuButtonSkin" */
    ),
  ],
  SeparatedArrowsMenuSkin: () => [
    import(
      './viewer/skinComps/SeparatedArrowsMenuSkin/SeparatedArrowsMenuSkin.skin' /* webpackChunkName: "DropDownMenu_SeparatedArrowsMenuSkin" */
    ),
  ],
  ShinyMenuIButtonSkin: () => [
    import(
      './viewer/skinComps/ShinyMenuIButtonSkin/ShinyMenuIButtonSkin.skin' /* webpackChunkName: "DropDownMenu_ShinyMenuIButtonSkin" */
    ),
  ],
  ShinyMenuIIButtonSkin: () => [
    import(
      './viewer/skinComps/ShinyMenuIIButtonSkin/ShinyMenuIIButtonSkin.skin' /* webpackChunkName: "DropDownMenu_ShinyMenuIIButtonSkin" */
    ),
  ],
  SloppyBorderMenuButtonSkin: () => [
    import(
      './viewer/skinComps/SloppyBorderMenuButtonSkin/SloppyBorderMenuButtonSkin.skin' /* webpackChunkName: "DropDownMenu_SloppyBorderMenuButtonSkin" */
    ),
  ],
  SolidColorMenuButtonSkin: () => [
    import(
      './viewer/skinComps/SolidColorMenuButtonSkin/SolidColorMenuButtonSkin.skin' /* webpackChunkName: "DropDownMenu_SolidColorMenuButtonSkin" */
    ),
  ],
  TextOnlyMenuButtonBgFixSkin: () => [
    import(
      './viewer/skinComps/TextOnlyMenuButtonBgFixSkin/TextOnlyMenuButtonBgFixSkin.skin' /* webpackChunkName: "DropDownMenu_TextOnlyMenuButtonBgFixSkin" */
    ),
  ],
  TextOnlyMenuButtonSkin: () => [
    import(
      './viewer/skinComps/TextOnlyMenuButtonSkin/TextOnlyMenuButtonSkin.skin' /* webpackChunkName: "DropDownMenu_TextOnlyMenuButtonSkin" */
    ),
  ],
  TextSeparatorsMenuButtonSkin: () => [
    import(
      './viewer/skinComps/TextSeparatorsMenuButtonSkin/TextSeparatorsMenuButtonSkin.skin' /* webpackChunkName: "DropDownMenu_TextSeparatorsMenuButtonSkin" */
    ),
  ],
  VerticalRibbonsMenuButtonSkin: () => [
    import(
      './viewer/skinComps/VerticalRibbonsMenuButtonSkin/VerticalRibbonsMenuButtonSkin.skin' /* webpackChunkName: "DropDownMenu_VerticalRibbonsMenuButtonSkin" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('DropDownMenu', UiTypes),
};

export default entry;
