import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  dropdown: () => [
    import(
      './viewer/skinComps/TimePickerDropdown/dropdown.skin' /* webpackChunkName: "TimePicker_dropdown" */
    ),
    import(
      './viewer/TimePicker.controller' /* webpackChunkName: "TimePicker_dropdown" */
    ),
  ],
  stepper: () => [
    import(
      './viewer/skinComps/TimePickerStepper/stepper.skin' /* webpackChunkName: "TimePicker_stepper" */
    ),
    import(
      './viewer/TimePicker.controller' /* webpackChunkName: "TimePicker_stepper" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('TimePicker', UiTypes),
};

export default entry;
